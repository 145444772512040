import React from "react"
import styled, { css } from "styled-components"
import { Link, useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faFacebookSquare,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons"
import projects from "../data/projects"
import players from "../data/players"

const links = [
  { to: "/about", text: "about us" },
  { to: "/visible-brand-actions", text: "visible brand actions™" },
  { to: "/projects", text: "projects" },
  { to: "/the-players", text: "the players" },
  { to: "/the-process", text: "the process" },
  { to: "/contact", text: "contact" },
  { to: "/news", text: "news" },
  { to: "https://instagram.com", text: faInstagram },
  {
    to: "https://facebook.com/Sambal-Lab-1015208305246927",
    text: faFacebookSquare,
  },
]

const StyledSideContent = styled.nav`
  background-color: ${props => props.theme.primary};

  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
  padding: 10px 0;
`

const StyledLink = styled(Link)`
  display: block;
  font-size: 14px;
  color: ${props =>
    props.active ? props.theme.activeText : props.theme.sideBarText};
  text-align: right;
  padding: ${props => (props.sublink ? "5px 15px" : "5px 0")};
  ${props =>
    props.sublink &&
    css`
      font-size: 12px;
    `};
  &:hover {
    color: ${props => props.theme.activeText} !important;
  }
`

const StyledA = styled.a`
  display: block;
  color: ${props => (props.active ? props.theme.activeText : "inherit")};
  text-align: right;
  padding: 5px 0;
  font-size: 1.5rem;
  &:hover {
    color: ${props => props.theme.activeText} !important;
  }
`

const SideContent = ({ activeMenu, activeSubMenu, sideToggle }) => {
  const { allContentfulNews } = useStaticQuery(graphql`
    query {
      allContentfulNews(sort: { fields: [publishedDate], order: DESC }) {
        edges {
          node {
            slug
          }
        }
      }
    }
  `)

  var mq =
    typeof window !== "undefined" && window.matchMedia("(min-width: 992px)")
  return (
    <StyledSideContent>
      {links.map((link, index) => {
        const isActive = activeMenu
          ? activeMenu.toLowerCase() === link.text
          : false
        return index < 7 ? (
          <div key={link.text}>
            <StyledLink
              to={`${link.to}/${
                index === 2
                  ? "guinness-taste-perfection"
                  : index === 6
                  ? allContentfulNews.edges[0].node.slug
                  : ""
              }`}
              active={isActive ? 1 : 0}
            >
              <span>{link.text}</span>
            </StyledLink>
            {index === 2 && activeMenu === "Projects"
              ? projects.map(project => {
                  const isSubMenuActive = activeSubMenu
                    ? activeSubMenu === project.id
                    : false
                  return (
                    <StyledLink
                      key={project.id}
                      to={`${link.to}/${project.id}`}
                      sublink={1}
                      active={isSubMenuActive ? 1 : 0}
                    >
                      <span>{project.title.toLowerCase()}</span>
                    </StyledLink>
                  )
                })
              : null}
            {index === 3 && activeMenu === "The players"
              ? players.map(player => {
                  const isSubMenuActive = activeSubMenu
                    ? activeSubMenu === player.id
                    : false
                  return (
                    <StyledLink
                      key={player.id}
                      to={`${link.to}/#${player.id}`}
                      sublink={1}
                      active={isSubMenuActive ? 1 : 0}
                      onClick={!mq.matches ? sideToggle : null}
                    >
                      <span>{player.name.split(",")[0].toLowerCase()}</span>
                    </StyledLink>
                  )
                })
              : null}
          </div>
        ) : (
          <StyledA key={index} href={link.to} target="_blank">
            <FontAwesomeIcon icon={link.text} />
          </StyledA>
        )
      })}
    </StyledSideContent>
  )
}

export default SideContent
