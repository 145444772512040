const projects = [
  {
    id: "guinness-taste-perfection",
    src: "guiness.png",
    title: "Guinness Taste Perfection",
    subtitle: "Experiential Activation",
    content:
      "<br/><b>Plot</b>" +
      "<br style='line-height:10px'/>After being dormant for years, a local porter unexpectedly launches a campaign in 2018, centred around quality ingredients and taste mirroring the brand assets of Guinness including techniques of a 'perfect pour'; it seemed adamant on saying it frequent enough till it becomes true. To set the record straight, Sambal Lab set the stage nationwide for immersive liquid-to-lip sessions by Guinness Brand Ambassador Cian Hulm to give Malaysian consumers and frontliners a hands-on experience in the secret Brewing Ingredients of Guinness and the Guinness Two-Part Pour.<br/>" +
      "<br/><br/><b>Performance</b>" +
      "<br/>13,000 people engaged, 17,000 glasses consumed, RM1 million in PR Value achieved. Nationwide sales growth of 20% of Guinness Draught at end of campaign, the highest in previous 12 months.<br/>" +
      "<br/><br/><b>Critical Review</b>" +
      "<br/>Guinness has never seen a significant growth for many years. Usually, it's a steady and gradual growth." +
      "<br/><small><i>Senior Brand Manager, Guinness Malaysia</i></small>" +
      "<br/><br/>Great to see how hard you (the Malaysian team and agency) are working Cian and the impact that he is having. The quality of execution of your perfect pint work is of exceptionally high quality. It looks extremely premium, crafted and very distinctive." +
      "<br/><small><i>Diageo Global Head of Beer, Baileys and Smirnoff</i></small>",
    background: "#020202",
  },
  {
    id: "jti-together-we-grow",
    src: "jti.png",
    title: "JTI Together, We Grow",
    subtitle: "Trade Loyalty Programme",
    content:
      "<br/><b>Plot</b>" +
      "<br/>Unfeasible to compete with the high-value trade loyalty rewards, gifts and cash of bigger tobacco companies, Sambal Lab work from ground up, meeting with and listening to what Retailers, Runcits and Frontliners themselves really want out of the trade partnership. The key take-out? Bigger companies often do not know their partners or understand their business. Gathered insights revealed how Provisions were losing out to hypermarts; they find it hard to evolve and stay competitive in the new landscape. Retailers were asking for anything that could help them grow. They simply had no idea where to start. Daily disruptions such as pest problems were quoted high on the list of business challenges.<br/>" +
      "<br/>While bigger tobacco companies offer ’emotional rewards’, Sambal Lab reposition JTI’s trade programme to one which understands its partners and their real-world business challenges.<br/>" +
      "<br/>The result : an expansive digital trade partnership programme rewarding loyal partners and sales performers with real business solutions and traditional gift rewards – while amassing precious Big Data on trade sales, activity and practise.<br/>" +
      "<br/><br/><b>Performance</b>" +
      "<br/>Projected ROI of 284% based on 15% margin in sales contribution.<br/>" +
      "<br/>Simple and delivers solutions that meet my immediate and long term needs." +
      "<br/><small><i>JTI Business Partners</i></small>" +
      "<br/><br/>The programme sees me as an individual and is not designed with my boss in mind; it provides me with support that helps me cope with life, tools for my personal development and enjoyment I hardly get on my own." +
      "<br/><small><i>JTI Trade Staff</i></small>",
    background: "#006633",
  },
  {
    id: "martell-pure-gourmet",
    src: "martel.png",
    title: "Martell Pure Gourmet",
    subtitle: "Experiential PR & Activation",
    content:
      "<br/><b>Plot</b>" +
      "<br/>In 2010 Martell unveils a gastronomic initiative, Martell Pure Gourmet, which brings together the House of Martell chefs and salon masters to create culinary pairings with the range’s cognacs around the world. Working from Pernod Ricard’s global brief, Sambal Lab propose a reinterpretation of its launch and execution for Malaysia, producing a series of silver-service gourmet experientials—many never attempted anywhere else in the world before for Pure Gourmet—over the next 5 to 6 years.<br/>" +
      "<br/><br/><b>Performance</b>" +
      "<br/>The Pure Gourmet farm media launch achieved 100% attendance, and returned a PR Value of RM600,000, thirty times the agency budget.<br/>" +
      "<br/>The House of Bufori event returned a PR Value of RM843,870, three times the agency budget. Despite the huge success, a later Ryan Clift event topped it by a significant 25%.<br/>" +
      "<br/>This Pure Gourmet brief was the only one ever executed this way. Globally.</br>" +
      "<small><i>Pernod Ricard</i></small></br>" +
      "<br/><br/><b>Accolades</b>" +
      "<br/>2012 AOTY MARKies Winner—Best Idea, Direct Mail for Pure Gourmet Media Farm Launch" +
      "<br/>2013 AOTY MARKies Finalist—Best Idea, Direct Mail for House of Bufori",
    background: "#000033",
  },
  {
    id: "hennessy-vs-&-vsop-cocktail",
    src: "hennessy.png",
    title: "Hennessy VS & VSOP Cocktail",
    subtitle: "PR Programme",
    content:
      "<br/><b>Plot</b>" +
      "<br/>It‘s November 2019, fast approaching the year-end festivities and house parties. Throughout the year, there has been a growing cocktail culture. Hennessy introduces a timely home cocktail set for young adults to make Hennessy a special part of the Golden Age of New Cocktails.<br/>" +
      "<br/>But what’s Hennessy Very Special to young Malaysian adults? Nothing special. In fact, many have no knowledge about cognacs. But as far as millennials and mixology are concerned, they can be experts in anything they put their mind into. Sambal Lab propose to sell precisely that attitude for Hennessy through 20 peer influencers, and at 0 ad spend.<br/>" +
      "<br/><br/><b>Performance</b>" +
      "<br/>94 Posts, 26 Peer Influencers, 0 Ad Spend.<br/>" +
      "<br/>Total Reach of 870,000.<br/>" +
      "<br/>810% in PR Value, or ROI of 2910% surpassing target of 1,200%.",
    background: "#000000",
  },
  {
    id: "jameson-not-so-serious",
    src: "jameson.png",
    title: "Jameson Not-So-Serious",
    subtitle: "Experiential PR & Activation",
    content:
      "<br/><b>Plot</b>" +
      "<br/>Pernod Ricard had no idea how to market Jameson in Malaysia. And therefore, how to invest in its growth locally. In the rest of the world, Jameson Irish Whiskey is a bit of a cult thing with its early humourous advertising campaigns selling the ‘easygoing approach to life‘ and its cult film screenings. In Malaysia, only the lads in the entertainment industry would know. But these lads were also key opinion leaders with considerable fanbase and social media following. The strategy? Get key influencers to engage and brand-trial their fanbase—in their own playful ways. In short, a serious but not-so-serious activation.<br/>" +
      "<br/><br/><b>Performance</b>" +
      "<br/>Total Reach of 44,925.<br/>" +
      "<br/>PR Value of RM410,118. 15 times the overall event budget.",
    background: "#003300",
  },
  {
    id: "hlb-jumpstart",
    src: "hlb.png",
    title: "HLB Jumpstart",
    subtitle: "Media Publicity Programme",
    content:
      "<br/><b>Plot</b>" +
      "<br/>Hong Leong Bank champions Malaysian social entrepreneurs who help build sustainable communities through the HLB Jumpstart platform which provides financial knowledge, branding and marketing, volunteership, tools & digitisation, and commercialisation. It is a quality CSR programme which deserves genuine, widespread media publicity to inspire more.<br/>" +
      "<br/>With no advertising support in the brief, Sambal Lab propose a PR programme which prioritises quality stories over quantity. The programme goes on to achieve high media publicity through mainstream media by offering their editors what they crave most—in-depth, impactful coverage.<br/>" +
      "<br/><br/><b>Performance</b>" +
      "<br/>46 Total Articles in Mainstream and Online Media.<br/>" +
      "<br/>24 Commendable Headlines from 1-on-1 Interviews and Press Releases.<br/>" +
      "<br/>RM978,129 in PR Value. 64% above KPI of RM600,000.",
    background: "#000033",
  },
  {
    id: "heineken's-the-great-brew-fest",
    src: "heineken.png",
    title: "Heineken’s The Great Brew Fest",
    subtitle: "360º Campaign",
    content:
      "<br/><b>Plot</b>" +
      "<br/>Sambal Lab bring together the disciplines of brand strategy, public relations, and experiential and digital activation to produce real and Visible Brand Actions™ to ‘elevate the beer culture’ in Malaysia in this 360º campaign for Heineken Malaysia.<br/>" +
      "<br/>Later when interviewed by a reporter who thought that there is already a beer drinking culture here, Heineken Malaysia Marketing Director Jiri Rakosnik would say: ‘There is a photograph of Bill Clinton, Madeleine Albright and former Czech president Václav Havel sitting in a pub in Prague, and that was their meeting place. When you sign big deals and have major meetings over a beer, then you have a beer culture!’.<br/>" +
      "<br/><br/><b>Performance</b>" +
      "<br/>RM2,523,011 in Total-Media PR Value or returns of 3,523%, from 286 clippings.<br/>" +
      "<br/>RM638,763 in PR Value from intimate media sessions alone, at a RM5,000 budget.<br/>" +
      "<br/>4,463,000 mL in Total Consumption.<br/>" +
      "<br/>2,129 Total Mugs Sold.<br/>" +
      "<br/>Sambal Lab’s comprehensive outlet-by-outlet post-evaluation revealed how the 360º campaign lacked big outlets to drive volume; nearly 40% of the outlets were not suitable, meaning there is plenty room for even higher volume sales.<br/>" +
      "<br/><br/><b>Critical Response</b>" +
      "<br/>The Robb Report, the American lifestyle magazine for the affluent, covered the beer festival, practically unheard of for a non-luxury product like beer.<br/>" +
      "<br/>In mainstream media, the focus on the name ‘Brew’ selling the ‘quality craftsmanship‘ story successfully avoided any political or religious backlash like ones experienced in 2017. Year 2018 reported some negative media sentiments on Oktoberfest but none near the Great Brew Fest. Without any association to Oktoberfest, media confidently and clearly featured the campaign name.<br/>" +
      "<br/>In digital media, the agency’s Facebook and Instagram strategies scored a cumulative reach of over 4 million and more than 5,000 new followers. The online audience had started responding to the event page two weeks ahead of the event. The highest performers were posts of the Mugshot consumer contest and the limited edition designer Festival Mugs.<br/>" +
      "<br/>Anticipating high profits to be made from the attractive limited edition festival mugs, many outlets priced them too high for consumer purchase. Even then, by the third week of activation, roving outlets had ran out of stock.",
    background: "#996600",
  },
]

module.exports = projects
