import React, { Fragment } from "react"
import styled, {
  css,
  createGlobalStyle,
  ThemeProvider,
} from "styled-components"
import color from "../themes/color"
import SideHeader from "./sideHeader"
import SideContent from "./sideContent"
import SideFooter from "./sideFooter"
import MainHeader from "./mainHeader"
import MainContent from "./mainContent"
// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
import "@fortawesome/fontawesome-svg-core/styles.css"
// Prevent fontawesome from adding its CSS since we did it manually above:
import { config } from "@fortawesome/fontawesome-svg-core"
config.autoAddCss = false /* eslint-disable import/first */

const GlobalStyle = createGlobalStyle`


    * {
        font-family: arial !important;
        margin: 0;
        padding: 0;

        a {
            text-decoration: none;
            &:hover {
                text-decoration: none;
            }
        }
    }

    ::-webkit-scrollbar {
        display: none;
      }


    html, body {
        overflow: auto;
        background-color: ${props => props.theme.primary};
        height: initial;

        ${props =>
          props.mobileOpen &&
          css`
            overflow: hidden;
            position: fixed;
            width: 100%;
            height: 100%;
          `}
            
        div[role='group'] {
            /* When elastic scrolling of the iphone, the properties belonging to the body are brought to the bottom div so that the fixed header is not blocked. */
            -webkit-overflow-scrolling: touch;
        }
    }
    p {
        color: white;
    }

    /* unvisited link */
    a:link {
      color: #ffcc99;
    }
  
    /* visited link */
    a:visited {
      color: #ffcc99;
    }
`

const SideBar = styled.div`
  min-width: 280px;
  height: 100%;
  position: fixed;
  z-index: 3;
  top: 0;
  right: 0;
  overflow-x: hidden;
  padding: 15px 30px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: ${props => props.theme.primary};
  color: ${props => props.theme.sideBarText} !important;

  transition: margin 0.5s;
  margin-right: ${props => (props.mobileOpen ? 0 : "-280px")};

  @media all and (min-width: 992px) {
    min-width: 280px;
    margin-right: 0 !important;
  }
`

const Main = styled.div`
  margin-right: 0;

  color: ${props => props.theme.headerText} !important;

  @media all and (min-width: 992px) {
    margin-right: 280px !important;
  }
`

const MainOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.overlayBackground};
  opacity: 0.8;

  z-index: 2;

  @media all and (min-width: 992px) {
    display: none;
  }
`

export default class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      mobile_side: false,
    }
  }

  themeToggle = e => {
    this.setState({
      isDarkMode: !this.state.isDarkMode,
    })
  }

  sideToggle = e => {
    const open = !this.state.mobile_side
    this.setState({
      mobile_side: open,
    })
  }

  render() {
    const { children, activeMenu, activeSubMenu } = this.props
    const { mobile_side } = this.state
    return (
      <ThemeProvider theme={color}>
        <Fragment>
          <GlobalStyle mobileOpen={mobile_side} />
          <SideBar mobileOpen={mobile_side}>
            <div>
              <SideHeader />
              <SideContent
                activeMenu={activeMenu}
                activeSubMenu={activeSubMenu}
                sideToggle={this.sideToggle}
              />
            </div>
            <SideFooter />
          </SideBar>
          <Main>
            {mobile_side && <MainOverlay onClick={this.sideToggle} />}
            <MainHeader sideToggle={this.sideToggle} />
            <MainContent>{children}</MainContent>
          </Main>
        </Fragment>
      </ThemeProvider>
    )
  }
}
