import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Logo from "./logo";

const StyledMainHeader = styled.header`
    width: 100%;
    height: 60px;
    position: fixed;
    z-index: 1;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 15px;

    color: ${props => props.theme.headerText};
    background-color: ${props => props.theme.primary};

    @media all and (min-width: 992px) {
        display: none;
    }
`;

const MenuButton = styled.button`
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    font-size: 1.5rem;
    outline: none;
    color: ${props => props.theme.icon};
`;

const MainHeader = ({ sideToggle }) => {
    return (
        <StyledMainHeader>
            <Link to={"/"}>
                <Logo />
            </Link>
            <MenuButton onClick={sideToggle}>
                <FontAwesomeIcon icon={faBars} />
            </MenuButton>
        </StyledMainHeader>
    );
};

export default MainHeader;
