import React from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const Copyright = styled.p`
    color: ${props => props.theme.sideBarText};
    font-size: 14px;
    margin-bottom: 0;
`;

const SideFooter = ({ children }) => {
    return <Container>
        <Copyright>© 2020 sambal lab sdn bhd</Copyright>
    </Container>;
};

export default SideFooter;
