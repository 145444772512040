import React from "react";
import styled from "styled-components";

const StyledMainContent = styled.section`
    overflow: auto;
    padding: 0 20px;
    ::-webkit-scrollbar {
        display: none;
    }

    @media all and (min-width: 992px) {
        padding: 0;
    }
`;

const MainContent = ({ children }) => {
    return <StyledMainContent>{children}</StyledMainContent>;
};

export default MainContent;
