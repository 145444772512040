const players = [
  {
    id: "balreet",
    src: "balreet.png",
    name: "Balreet Gill, Founder",
    biography:
      "<br/>Balreet made her debut at multinational agencies TBWA and G2 in the 2000s and quickly became a star. She had worked with the likes of Austen Zecha, Chris Galea and Bas Moreu, playing lead roles across financial, beverage and lifestyle brand platforms and campaigns. But it is her work in the sin industry that has made her who she is. Acclaimed as the industry’s master of dark marketing for her pioneering work on Malaysia’s landmark tobacco campaigns Winston Freedom, and Salem Recharge and Revelation, Balreet later decided to set out exploring new approaches in brand experiential.<br/>" +
      "<br/>Her new path began with Sambal Lab, a strat startup she founded in 2010. Now with twenty years in the business and heart-mind-soul invested in the luxury market, she brings brands to life in ways that big agencies have never seen. Her forte remains experiential campaigns. Her flair cannot be taught. Her methods, sublime.<br/>",
  },
  {
    id: "jodh",
    src: "jodh.png",
    name: "Jodh Dheensay, Research & Project Head",
    biography:
      "<br/>Jodh began his career with the family furniture business at an early age where he learned the hard business of operations, strategic planning, manufacturing, and international marketing. It is here that he first gained the knowledge of growing and keeping a company profitable. He soon ventured into interior building and fit-outs while he started freelancing on creative projects on a renewed interest in entertainment, media and production.<br/>" +
      "<br/>Wonderfully kind and scholarly, often Jodh has to sugarcoat his esoteric views. An unorthodox researcher, he simultaneously dives into Gnosticism and draws from Nietzsche to read consumers beneath the surface, to resonate with them in meaningful ways. In 2010, Jodh joined Sambal Lab as Partner to focus on his first loves—research & analysis, strategy, and production—and to help develop the business and the brand.<br/>",
  },
  {
    id: "jeninder",
    src: "jeninder.png",
    name: "Jeninder Kaur Gill, PR Head",
    biography:
      "<br/>After five years with a leading stock-broking firm, Jenny found her calling in marketing communications and Public Relations. She honed her skills in corporate comms, CSR and social PR. Over the next ten years, she held progressive roles at Grey Worldwide and Leo Burnett, working on multinational, consumer, and corporate accounts. Joining Sambal Lab in 2011, Jenny began working on luxury and lifestyle brands, before gradually expanding her portfolio to include local capital markets. Based in Malaysia, she was frequently transported to the global financial centres of Hong Kong, Singapore and London where she activated a number of campaigns representing Malaysian capital markets institutions.<br/>" +
      "<br/>Jenny’s keen wit and unconventional approach have their roots—being an afficionado of British comedy classics and a pianist who listens to Led Zep. Her genuine disposition has cultivated a special relationship with media over the years; her left-field PR angles intrigue KOLs; and her brand-storied press releases bowl over clients. Jenny brings to the table more than two decades of PR-savvy and experience.<br/>",
  },
  {
    id: "the-team",
    src: "team.png",
    name: "The Ensemble",
    biography:
      "<br/>Our projects succeed because every artist, designer, planner and manager brings something distinct to the work. We share equal importance in the narrative and each one of us becomes part of the Sambal Lab brand story. One which successfully balances profession with passion.<br/>",
  },
]

export default players
