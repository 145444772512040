import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Logo from "./logo";

const Container = styled(Link)`
    padding: 10px 0;
    display: flex;
    justify-content: flex-end;
`;

const SideHeader = () => {
    return (
        <Container to={"/"}>
            <Logo />
        </Container>
    );
};

export default SideHeader;
